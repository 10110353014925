export const localStorageSetItemWrapper = (k, v) => {

    /*
    Currently, we store execution results in localStorage.  We are aware that browsers do impose 
    limit on the size of localStorage.  The purpose of this function is to avoid running into 
    this limit.

    Previously, we wait for the localStorage to be full, it throws an exception, and then we 
    try to delete some older data and retry.  However, from what I read, waiting for localStorage 
    to be full may lead to slowness.  So now, we switch to deleting old data first.

    If we ever run into localStorage limit, try to use the cleanupLocalStorageAggressive function.
    It takes one parameter which represent the number of days of data that we wish to keep in 
    localStorage.  If we want to delete everything, specify the number of days as 0.

    We are using localStorage to store execution results because that was what we initially started 
    out with, but then we realize that browser do impose limits on localStorage size.  We are using 
    it to remember input parameters and output parameters for the next time we need to re-run a 
    task or re-run a child task, or if we need to manually execute each task.  We did consider 
    getting this information from the back-end, but that may require some work.
    */

    // First delete data that are older than 2 weeks, but we do not want to do this clean up on 
    // every time we need to store some result into localStorage though.  We only want to do this 
    // clean up once a day.
    let last_clean_up_time = localStorage.getItem('last_clean_up');
    let now = (new Date()).getTime() / 1000;
    let clean_up_performed_today = false;
    if (last_clean_up_time != null) {
        if (now - last_clean_up_time <= 1 * 24 * 60 * 60) {
            clean_up_performed_today = true;
        }
    }

    if (clean_up_performed_today === false) {
        let to_be_deleted = [];
        try {
            let current_count = localStorage.length;
            let i = 0;
            for (i = 0; i < current_count; i++) {
                let key = localStorage.key(i);
                if ((key.startsWith('paramvals')) || (key.startsWith('specials'))) {
                    let obj = localStorage.getItem(key);
                    if (obj != null) {
                        obj = JSON.parse(obj);
                        if ((obj.hasOwnProperty('localStorage_lastUpdatedTimestamp')) && (now - obj['localStorage_lastUpdatedTimestamp'] >= 14 * 24 * 60 * 60)) {
                            to_be_deleted.push(key);
                        }
                    }
                }
            }
        } catch (e2) {
            // If an exception was thrown here, I think we can ignore.
        }
    
        try {
            let i = 0;
            for (i = 0; i < to_be_deleted.length; i++) {
                let key = to_be_deleted[i];
                localStorage.removeItem(key);
            }
        } catch (e3) {
            // If an exception was thrown here, I think we can ignore.
        }

        localStorage.setItem('last_clean_up_time', now + '');
    }


    // Now try to store the desired item
    try {
        localStorage.setItem(k, v);
    } catch (e1) {
        // If we cannot store the desired item, log a message to the console.
        console.warn("Not able to insert into localStorage.  Exception:", e1);
    }
}
  